import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import IframeForm from "../components/IframeForm/IframeForm"

const InquiryPage = () => (
  <Layout>
    <SEO 
      title="Inquire" 
      keywords="translation, localisation, localization, English to Tagalog, Tagalog to English, cultural translation, cultural naturalization, cultural naturalisation, production management, voice acting, voice actors, voice actor management, dubbing, voice over, Australia, Philippines, Sleigh Platform, subtitles, subtitling, Synchresis, language services"
    />
        
        <IframeForm 
        text= {
        <div style={{maxWidth: '880px', margin: 'auto'}}>
          <p style={{fontSize: '14px'}}>
            Thank you for your interest in Sleigh Group!
          </p>

          <p style={{fontSize: '14px'}}>
            Please use the following form to open a conversation about how we can help you with your localization project. 
            For all other inquiries, please drop us an email at   
            <a href="mailto:sales@sleigh.group" class="cryptedmail"
                                                data-name="sales"
                                                data-domain="sleigh"
                                                data-tld="group"></a>.
          </p>
        </div>}
        iframe='<iframe src="https://www.cognitoforms.com/f/t9o-8CFsP0qOlWtp0D9pyw?id=30" style="position:relative;width:1px;min-width:100%;*width:100%;" frameborder="0" scrolling="yes" seamless="seamless" height="586" width="100%"></iframe>'/>
  </Layout>
)

export default InquiryPage
